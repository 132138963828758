import React, { useEffect, useState } from "react";
import GoogleMapReact from "google-map-react";
import "./custom-map.scss";
import config from "../../../config";
import { isEmpty, isUndefined } from "lodash";
import PropTypes from "prop-types";
import { isMobile } from "react-device-detect";
import { getMobileOperatingSystem } from "../../../helpers/helpers";
import clsx from "clsx";

const CustomMap = ({ property, mapOverlayed }) => {
    const [customFullscreen, setCustomFullscreen] = useState(false);
    const [center, setCenter] = useState([41.8848, 88.204]);
    const [mapRefs, setMapRefs] = useState({ map: {}, maps: {} });
    const [boundChangeListener, setBoundChangedListener] = useState(null);
    const [mapError, setMapError] = useState(null);
    const { isAndroid } = getMobileOperatingSystem();
    const defaultProps = {
        zoom: 15,
    };

    const handleMapError = (error) => {
        console.error("Map error:", error);
        setMapError(error?.message || "An unexpected error occurred while loading the map.");
    };

    const handleReload = () => {
        setMapError(null); // Reset the error before retrying
        window.location.reload(true);
    };

    useEffect(() => {
        if (!property || isEmpty(property.id) || !window.google || !window.google.maps) return;

        // Clear previous map bound change listener
        if (boundChangeListener) {
            boundChangeListener.remove();
            setBoundChangedListener(null);
        }

        // Clear the previous map's reference
        if (mapRefs.map) {
            setMapRefs({ map: {}, maps: {} });
        }

        /**
         * Fetches the coordinates (latitude and longitude) for a given address using the Geocoding API.
         * Updates the center state with the obtained coordinates.
         */
        async function getCoords() {
            let geocoder = window.google.maps.Geocoder;

            // If there is no Geocoder class instance in the window object, import it
            if (isUndefined(geocoder)) {
                try {
                    const { Geocoder } = await new window.google.maps.importLibrary("geocoding");
                    geocoder = Geocoder;
                } catch (error) {
                    console.error("Error loading Google Maps Geocoding library:", error);
                    setMapError("Could not load geocoding service.");
                    return;
                }
            }

            // We must parse the full address so that the Geocode will work properly and accurately
            const fullAddress = [
                property.street_address,
                property.city,
                property.state,
                property.zip_code,
            ].join(",");

            new geocoder().geocode({ address: fullAddress }, (results, status) => {
                if (status === "OK") {
                    const lat = results[0].geometry.location?.lat();
                    const lng = results[0].geometry.location?.lng();
                    setCenter([lat, lng]);
                } else {
                    alert("Geocode was not successful for the following reason: " + status);
                }
            });
        }

        getCoords();
    }, [property]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const originalHandler = window.onerror;

        window.onerror = (message, source, lineno, colno, error) => {
            if (typeof message === "string" && message.includes("OverQuotaMapError")) {
                console.error("Quota error detected:", error);
                setMapError("Map usage quota exceeded. Please try again later.");
            }
            if (typeof originalHandler === "function" && originalHandler) {
                originalHandler(message, source, lineno, colno, error);
            }
        };

        return () => {
            window.onerror = originalHandler;
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Handles the callback function when the Google Maps API is loaded.
     * Initializes the map, adds a center marker, and sets up event listeners.
     *
     * @param {Object} map - The map object provided by the Google Maps API.
     * @param {Object} maps - The maps object provided by the Google Maps API.
     */
    const apiIsLoaded = (map, maps) => {
        // We need to import the places library here for the Select Location modal
        window.google.maps.importLibrary("places");

        setMapRefs({
            map: map,
            maps: maps,
        });

        let bResetMapEventFired = true;
        let bFromFullScreenMode = false;

        // Add the center marker
        const marker = new maps.Marker({
            position: { lat: center[0], lng: center[1] },
            map,
        });

        if (mapOverlayed) {
            const url = "https://maps.gstatic.com/mapfiles/api-3/images/spotlight-poi.png";
            const smallMarker = new window.google.maps.Size(10, 15);
            const defaultMarker = new window.google.maps.Size(20, 34);

            marker.setIcon({
                url,
                scaledSize: smallMarker,
            });

            // Function to find the fullscreen button and attach the necessary events
            const addFullscreenListener = () => {
                const fullScreenControlButton = map
                    .getDiv()
                    ?.querySelector("button[title='Toggle fullscreen view']");

                if (fullScreenControlButton) {
                    // Set initial opacity
                    fullScreenControlButton.style.opacity = 0; // Hide initially

                    // Add event listeners for fullscreen change
                    map.getDiv().addEventListener("fullscreenchange", () => {
                        if (document.fullscreenElement) {
                            // In fullscreen mode
                            fullScreenControlButton.style.opacity = 1;

                            marker.setIcon({
                                url,
                                scaledSize: defaultMarker,
                            });
                        } else {
                            // Not in fullscreen mode
                            fullScreenControlButton.style.opacity = 0;

                            marker.setIcon({
                                url,
                                scaledSize: smallMarker,
                            });
                        }
                    });
                } else {
                    // Try again if the button is not found
                    setTimeout(addFullscreenListener, 100);
                }
            };

            // Start checking for the fullscreen button
            addFullscreenListener();
        }

        // Only add the custom fullscreen control for IOS devices (https://app.asana.com/0/1204535088114623/1205433170702940/f)
        if (isMobile && !isAndroid && !mapOverlayed) {
            // Create the DIV to hold the control.
            const centerControlDiv = document.createElement("div");

            // Create the control.
            const centerControl = createCustomFullScreenControl();

            // Append the control to the DIV.
            centerControlDiv.appendChild(centerControl);
            map.controls[maps.ControlPosition.TOP_RIGHT].push(centerControlDiv);
        }

        const boundListener = map.addListener("bounds_changed", () => {
            // Check if the map's height and width is the same as the windows
            // If yes, then the map is fullscreen
            // If no, then the window might just be in fullscreen and not the map
            if (
                map.getDiv().children[0].offsetHeight === window.innerHeight &&
                map.getDiv().children[0].offsetWidth === window.innerWidth
            ) {
                bFromFullScreenMode = true;
                bResetMapEventFired = false;

                // Map is in fullscreen mode, reinstate all controls
                map.setOptions({ gestureHandling: "auto" });
            } else {
                // If they are both true, means we went from fullscreen to default view and we need to trigger a reset
                if (bResetMapEventFired === true && bFromFullScreenMode === true) {
                    bResetMapEventFired = false;
                }

                // If the Map is not in fullscreen mode, restrict panning and return to the center
                if (!bResetMapEventFired && bFromFullScreenMode) {
                    // map.setOptions({ gestureHandling: "none" });
                    // We need to set the flag to true to prevent continuous event firing
                    bResetMapEventFired = true;
                    bFromFullScreenMode = false;

                    map.setCenter({ lat: center[0], lng: center[1] });
                    map.setZoom(15);
                } else {
                    // We need to set the flag to false to let the event fire once
                    bResetMapEventFired = false;
                }
            }
        });

        setBoundChangedListener(boundListener);
    };

    /**
     * Creates a custom fullscreen toggle control button.
     *
     * @returns {HTMLButtonElement} The custom fullscreen control button element.
     */
    const createCustomFullScreenControl = () => {
        const controlButton = document.createElement("button");
        let fullscreen = false;

        controlButton.className = "custom-fullscreen-toggle-ios";
        controlButton.innerHTML =
            '<img src="data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%2018%2018%22%3E%3Cpath%20fill%3D%22%23666%22%20d%3D%22M0%200v6h2V2h4V0H0zm16%200h-4v2h4v4h2V0h-2zm0%2016h-4v2h6v-6h-2v4zM2%2012H0v6h6v-2H2v-4z%22/%3E%3C/svg%3E" alt="" style="height: 18px; width: 18px;"></img>';
        controlButton.title = "Click to toggle fullscreen";
        controlButton.type = "button";

        // Define the click event handler for toggling fullscreen.
        controlButton.onclick = () => {
            fullscreen = !fullscreen;

            setCustomFullscreen(fullscreen);
        };

        return controlButton;
    };

    return (
        <div
            className={clsx(
                "custom-map",
                customFullscreen && "full-screen-ios",
                mapOverlayed && "map-overlayed"
            )}
        >
            {mapError ? (
                <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-h-full tw-bg-gray-100 tw-p-6 tw-rounded-lg tw-shadow-lg tw-text-center">
                    <p className="tw-text-lg tw-font-semibold tw-text-gray-700 tw-mb-2">
                        Sorry, we couldn't load the map. Please try again.
                    </p>
                    <p className="tw-text-sm tw-text-red-500 tw-mb-4">
                        {mapError} {/* Display the actual error message */}
                    </p>
                    <button
                        onClick={handleReload}
                        className="tw-px-4 tw-py-2 tw-bg-blue-500 tw-text-white tw-font-semibold tw-rounded hover:tw-bg-blue-600 tw-transition tw-duration-200"
                    >
                        Retry
                    </button>
                </div>
            ) : center ? (
                <GoogleMapReact
                    fullscreenControl
                    data-cy="custom-map"
                    test-id="google-map"
                    key={center[0] + center[1]}
                    bootstrapURLKeys={{ key: config.GOOGLE_MAPS_API_KEY }}
                    center={center}
                    defaultZoom={defaultProps.zoom}
                    onGoogleApiLoaded={({ map, maps, ref }) => apiIsLoaded(map, maps, ref)}
                    onError={handleMapError}
                    yesIWantToUseGoogleMapApiInternals
                ></GoogleMapReact>
            ) : (
                <div>Loading map...</div>
            )}
        </div>
    );
};

CustomMap.propTypes = {
    property: PropTypes.object.isRequired,
    mapOverlayed: PropTypes.bool,
};

export default CustomMap;
