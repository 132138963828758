import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useQuery } from "@tanstack/react-query";
import { get, random, find, split } from "lodash";
import Sticky from "react-sticky-el";
import MoonLoader from "react-spinners/MoonLoader";
import config from "../../../../config";
import moment from "moment";
import { triggerShare } from "../../../../helpers/triggerShare";

import PropertyInfo from "../../../../components/common/PropertyInfo";
import CustomButton from "../../../../components/common/buttons/custom-button/custom-button";
import CustomSvg from "../../../../components/common/custom-svg/custom-svg";
import DailyScoreModal from "../../../../components/modals/LiveVariant/DailyScoreModal";

import { LIVE_VARIANT_VERSIONS } from "../../../../constants/live-variant-versions";
import { getLiveVariantDailyProperty } from "../../../../services/PropertyService";
import { formatPrice } from "../../../../helpers/helpers";
import useGAEvent from "../../../../hooks/useGAEvent";
import useLocalStorage from "../../../../hooks/useLocalStorage";
import useModalState from "../../../../hooks/useModalState";

import HeartOutlined from "../../../../assets/icons-v2/heart_outlined.png";
import HeartSolid from "../../../../assets/icons-v2/heart_solid.png";
import CheckmarkConfetti from "../../../../components/animations/CheckmarkConfetti";
import NumberRoller from "../../../../components/animations/NumberRoller";
import clsx from "clsx";
import NumberScrambler from "../../../../components/animations/NumberScrambler";
import AnimateShakeAndFade from "../../../../components/animations/AnimateShakeAndFade";
import IncorrectError from "../../../../components/animations/InvalidError";

/**
 * Live-variant game component that allows users to guess whether the price is higher or lower.
 *
 * @component
 * @param {object} props - The component props.
 * @param {string} props.version - The version of the Live-variant game.
 * @returns {JSX.Element} The Live-variant game interface.
 */
export default function OverUnder({ version }) {
    const bodyRef = useRef(null);
    const [guessPrice, setGuessPrice] = useState(null);
    const [points, setPoints] = useState(0);
    const [variance, setVariance] = useState(null);
    const [property, setProperty] = useState(null);
    const [showModal, setShowModal] = useState("");
    const { sendEvent } = useGAEvent();
    const currentVersion = LIVE_VARIANT_VERSIONS[version];
    const VARIANCE_RANGE = currentVersion?.variance;
    const maxTries = currentVersion?.max_tries ?? null;
    const [tries, setTries] = useState(0);
    const { setModalId } = useModalState();
    const [shownHelpModals, setShownHelpModal] = useLocalStorage("shown-help-modals", []);
    const [guessedProperties, setGuessedProperties] = useLocalStorage("guessed_properties", []);
    const [guessedPropertyResults, setGuessedPropertyResults] = useLocalStorage(
        "guessed_property_results",
        []
    );
    const [visitorStatus] = useLocalStorage("visitor-status");
    const [showConfetti, setShowConfetti] = useState(false);
    const [showIncorrect, setShowIncorrect] = useState(false);

    const guessedResult = find(
        guessedPropertyResults,
        (result) => result.property.mls_id === property?.mls_id && result?.version === version
    );

    // Check if this property instance has already been guessed
    const isPropertyGuessed = guessedProperties?.includes(property?.mls_id) && guessedResult;

    if (isPropertyGuessed && maxTries !== tries) {
        setTries(maxTries);
    }

    const dailyProperties = useQuery({
        queryKey: ["live-variant-daily-property"],
        queryFn: getLiveVariantDailyProperty,
        enabled: true,
        cacheTime: 0,
        onSuccess: (data) => {
            const property = get(data, "data.properties", null);
            if (property?.price) {
                setProperty(property);
                const actualPrice = property.price;
                const initialVariance = variance || random(VARIANCE_RANGE?.start, VARIANCE_RANGE?.end);
                const initialGuessPrice =
                    Math.random() < 0.5
                        ? (1 - initialVariance) * actualPrice
                        : (1 + initialVariance) * actualPrice;

                setGuessPrice(initialGuessPrice);
                setVariance(initialVariance);
            }
        },
    });

    const isLoading = dailyProperties?.isLoading || dailyProperties?.isFetching || !property;
    const actualPrice = property?.price;

    /**
     * Handles the user's guess for whether the price is higher or lower.
     *
     * @param {string} buttonType - The type of guess ("lower" or "higher").
     */
    const handleClick = (buttonType) => {
        if (!actualPrice || !guessPrice || !variance) {
            console.error("Missing values in calculations!");
            return;
        }

        let newVariance = variance * random(VARIANCE_RANGE.start, VARIANCE_RANGE.end);

        const newGuessPrice =
            Math.random() < 0.5 ? (1 - newVariance) * actualPrice : (1 + newVariance) * actualPrice;

        const newPoints = points + 1;
        const newTries = tries + 1;
        const correct =
            (buttonType === "lower" && actualPrice < guessPrice) ||
            (buttonType === "higher" && actualPrice > guessPrice);

        sendEvent("enter_guess", {
            liveVariantVersion: currentVersion?.gaVersion,
            visitorStatus: visitorStatus || "new",
            userScore: points,
        });

        // If correct: play the confetti, then the score, and then the price animations sequentially.
        if (correct) {
            setShowConfetti(true);
            setTimeout(() => setPoints(newPoints), 1500);
            setTimeout(() => setGuessPrice(newGuessPrice), 2000);
            setVariance(newVariance);
        } else {
            setTries(newTries);
            setShowIncorrect(true);

            setTimeout(() => {
                if (newTries === maxTries) {
                    // Store the property ID in guessed_properties
                    if (!isPropertyGuessed) {
                        setGuessedProperties([...guessedProperties, property.mls_id]);
                    }

                    // Store the result in guessed_property_results
                    setGuessedPropertyResults([
                        ...guessedPropertyResults,
                        { property, guessPrice, points, version },
                    ]);

                    setShowModal("dailyscore");
                } else {
                    setGuessPrice(newGuessPrice);
                }
            }, 3000);
        }
    };

    const handleShare = async () => {
        const score = guessedResult?.points || points;

        sendEvent("daily_score_share_score", {
            userScore: score,
            liveVariantVersion: currentVersion?.gaVersion,
            visitorStatus: visitorStatus || "new",
        });

        const currentDate = moment().tz("America/Chicago").format("MMM D, YYYY");
        const shareTextTitle = `PriceMe Total Score: ${score ?? 0}\n`;
        const shareTextBody = `${split(property.street_address, ",")[0] || property.neighborhood}\n${currentDate}`;
        const shareUrl = `${config.LIVE_VARIANT_BASE_URL}/${currentVersion.url}?medium=share`;

        await triggerShare({
            web: {
                text: `${shareTextTitle}${shareTextBody}\n\n${shareUrl}`,
            },
            mobile: {
                title: shareTextTitle,
                text: shareTextBody,
                url: shareUrl,
            },
        });
    };

    const renderHearts = () => {
        return Array.from({ length: maxTries }, (_, index) => {
            const isLost = index < tries;

            return (
                <AnimateShakeAndFade play={isLost} className="tw-mr-1">
                    <CustomSvg
                        key={index}
                        src={isLost ? HeartOutlined : HeartSolid}
                        size={{ width: isLost ? 22 : 20 }}
                    />
                </AnimateShakeAndFade>
            );
        });
    };

    useEffect(() => {
        if (!dailyProperties?.isLoading && !dailyProperties?.isFetching && !property) {
            // try to refetch when property is not yet set
            dailyProperties.refetch();
        }
    }, [property]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!isLoading && !shownHelpModals?.includes(version)) {
            setShownHelpModal([...shownHelpModals, version]);
            setModalId("LIVE_VARIANT_HELP_MODAL");
        }
    }, [isLoading, shownHelpModals]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (property) {
            sendEvent("start_page_load", {
                liveVariantVersion: currentVersion?.gaVersion,
                visitorStatus: visitorStatus || "new",
            });
        }
    }, [property]); // eslint-disable-line react-hooks/exhaustive-deps

    if (dailyProperties?.error) {
        return (
            <div className="tw-flex tw-items-center tw-justify-center tw-h-3/4 tw-p-8">
                {dailyProperties.error.response?.data?.message ||
                    "Failed to load daily property. Please try again later."}
            </div>
        );
    }

    return isLoading ? (
        <div className="tw-flex tw-items-center justify-content-center tw-h-3/4">
            <MoonLoader size={40} color="#63c19f" />
        </div>
    ) : (
        <div className="property-container" ref={bodyRef}>
            <CheckmarkConfetti play={showConfetti} setPlay={setShowConfetti} />
            <IncorrectError play={showIncorrect} setPlay={setShowIncorrect} />

            <PropertyInfo property={property} />

            <Sticky className="guess-input-sticky tw-z-[999]" mode="bottom" positionRecheckInterval={50}>
                <div className="!tw-bg-gray-902 guess-input-container tw-overflow-hidden px-3 !tw-pt-6 tw-pb-2 guess-input-container-shadow">
                    <div className="tw-bg-orange-901 tw-w-full tw-flex tw-items-center tw-rounded-500px">
                        {isPropertyGuessed ? (
                            <>
                                <div className="tw-w-1/2 tw-bg-white tw-font-bold tw-text-center tw-italic tw-py-0.5 tw-leading-10 tw-font-noto tw-text-lg tw-rounded-tl-[500px] tw-rounded-bl-[500px]">
                                    {formatPrice(actualPrice)}
                                </div>
                                <div className="tw-w-1/2">
                                    <CustomButton
                                        className="tw-w-full tw-flex tw-items-center tw-justify-center gold-solid tw-rounded-500px"
                                        handleClick={() => setShowModal("dailyscore")}
                                        text="See Results"
                                    />
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="tw-w-1/3">
                                    <CustomButton
                                        className="tw-w-full tw-flex tw-items-center tw-justify-center gold-solid tw-rounded-tl-[500px] tw-rounded-bl-[500px]"
                                        handleClick={() => handleClick("lower")}
                                        text="&#9660; Lower"
                                        isDisabled={showModal === "done"}
                                    />
                                </div>
                                <div className="tw-w-2/5 tw-bg-white tw-font-bold tw-text-center tw-py-0.5 tw-leading-10 tw-font-noto tw-text-lg">
                                    <NumberScrambler
                                        targetNumber={formatPrice(guessPrice)}
                                        duration={0.7}
                                        scrambleInterval={25}
                                    />
                                </div>
                                <div className="tw-w-1/3">
                                    <CustomButton
                                        className="tw-w-full tw-flex tw-items-center tw-justify-center gold-solid tw-rounded-tr-[500px] tw-rounded-br-[500px]"
                                        handleClick={() => handleClick("higher")}
                                        text="&#9650; Higher"
                                        isDisabled={showModal === "done"}
                                    />
                                </div>
                            </>
                        )}
                    </div>

                    <div className="tw-flex tw-items-center tw-justify-center">
                        <div className="tw-w-[70%] tw-justify-end score-to-beat !tw-max-w-full tw-px-3 tw-flex tw-gap-1">
                            <span
                                className={clsx(
                                    points >= 100 ? "tw-w-[71%]" : "tw-w-[81%]",
                                    "tw-text-gray-901 text-sm tw-font-noto tw-text-right"
                                )}
                            >
                                Your Score:
                            </span>
                            <span
                                className={clsx(
                                    points >= 100 ? "tw-w-[19%]" : "tw-w-[13%]",
                                    "app-text-title tw-text-yellow-901 !tw-font-noto score !tw-text-[21px] tw-text-left"
                                )}
                            >
                                <NumberRoller targetNumber={guessedResult?.points || points} />
                            </span>
                        </div>
                        <div className="tw-w-[30%] tw-flex tw-justify-center tw-items-center tw-gap-1">
                            {renderHearts()}
                        </div>
                    </div>
                </div>
            </Sticky>
            {showModal === "dailyscore" && (
                <DailyScoreModal
                    show={showModal === "dailyscore"}
                    handleClose={() => setShowModal("done")}
                    score={guessedResult?.points || points}
                    actualPrice={formatPrice(actualPrice)}
                    version={currentVersion}
                    property={property}
                    handleShare={handleShare}
                />
            )}
        </div>
    );
}

OverUnder.propTypes = {
    version: PropTypes.string.isRequired,
};
