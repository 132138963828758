import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { formatPrice } from "../../helpers/helpers";
import PropTypes from "prop-types";

const NumberScrambler = ({ targetNumber, duration = 2, scrambleInterval = 100 }) => {
    const [displayedNumber, setDisplayedNumber] = useState(targetNumber);
    const [scrambling, setScrambling] = useState(true);

    useEffect(() => {
        let intervalId;
        let timeoutId;

        if (displayedNumber !== targetNumber) {
            setScrambling(true);
        }

        if (scrambling) {
            // Start scrambling by updating the displayed number every 'scrambleInterval'
            intervalId = setInterval(() => {
                const randomScramble = Math.trunc(
                    Math.floor(
                        Math.random() * Math.pow(10, targetNumber.toString().replace(/[^\d.-]/g, "").length)
                    )
                );
                setDisplayedNumber(formatPrice(randomScramble));
            }, scrambleInterval);

            // After 'duration', stop scrambling and show the final target number
            timeoutId = setTimeout(() => {
                setScrambling(false);
                setDisplayedNumber(targetNumber); // Set the final number
                clearInterval(intervalId); // Stop the scrambling effect
            }, duration * 1000);
        }

        // Cleanup the interval and timeout on component unmount or when scrambling ends
        return () => {
            clearTimeout(timeoutId);
            clearInterval(intervalId);
        };
    }, [scrambling, targetNumber, duration, scrambleInterval]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.25 }}>
            {displayedNumber}
        </motion.div>
    );
};

NumberScrambler.propTypes = {
    targetNumber: PropTypes.number.isRequired,
    duration: PropTypes.number,
    scrambleInterval: PropTypes.number,
};

export default NumberScrambler;
