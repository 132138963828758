import React, { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import IncorrectGuessIcon from "../../assets/icons-v2/incorrect-guess.png";
import CustomSvg from "../common/custom-svg/custom-svg";
import PropTypes from "prop-types";

const DEFAULT = {
    durations: {
        incorrectShow: 0,
        pulseShow: 1400,
        hideAll: 2500,
    },
    incorrectOptions: {
        animation: {
            initial: { y: "25svh", opacity: 0 },
            animate: { y: "-25svh", opacity: 1 },
            exit: { y: "25vh", opacity: 0 },
            transition: { type: "spring", stiffness: 50, damping: 10 },
        },
        svg: {
            src: IncorrectGuessIcon,
            size: { height: 100, width: 100 },
        },
    },
    pulseOptions: {
        initial: { scale: 1, opacity: 0 },
        animate: { scale: 1.15, opacity: 0.5 }, // Pulse grows and fades out
        exit: { opacity: 0 },
        transition: { duration: 0.3, repeat: Infinity, repeatType: "reverse" },
    },
};

const IncorrectError = ({ play, setPlay }) => {
    const [incorrectVisible, setIncorrectVisible] = useState(false);
    const [showPulse, setShowPulse] = useState(false);

    useEffect(() => {
        let timers = [];

        if (play) {
            // Trigger the incorrect icon after a short delay
            timers.push(
                setTimeout(() => {
                    setIncorrectVisible(true);
                }, DEFAULT.durations.incorrectShow)
            );

            // Trigger the pulse after a short delay
            timers.push(
                setTimeout(() => {
                    setShowPulse(true);
                }, DEFAULT.durations.pulseShow)
            );

            // Reset the incorrect icon and pulse after the specified duration
            timers.push(
                setTimeout(() => {
                    setShowPulse(false);
                    setIncorrectVisible(false);
                    setPlay(false);
                }, DEFAULT.durations.hideAll)
            );
        }

        // Clear timers on unmount or if the `play` prop changes
        return () => {
            timers.forEach((timer) => clearTimeout(timer));
        };

        // Trigger the animation whenever `play` changes
    }, [play]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            <AnimatePresence>
                {incorrectVisible && (
                    <>
                        <motion.div
                            className="tw-fixed tw-left-[38%] tw-bottom-0 tw-z-[999]"
                            key="incorrect-svg-icon"
                            {...DEFAULT.incorrectOptions.animation}
                        >
                            <CustomSvg {...DEFAULT.incorrectOptions.svg} />
                        </motion.div>
                    </>
                )}
            </AnimatePresence>

            {/* Red Pulse Effect */}
            {showPulse && (
                <motion.div
                    className="tw-fixed tw-bottom-[25svh] tw-left-[38%] tw-z-[998] tw-bg-red-500 tw-rounded-full tw-w-[100px] tw-h-[100px]"
                    key="incorrect-pulse"
                    initial={DEFAULT.pulseOptions.initial}
                    animate={DEFAULT.pulseOptions.animate}
                    transition={DEFAULT.pulseOptions.transition}
                />
            )}
        </div>
    );
};

IncorrectError.propTypes = {
    play: PropTypes.bool.isRequired,
    setPlay: PropTypes.func.isRequired,
};

export default IncorrectError;
