import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Modal from "react-bootstrap/Modal";
import moment from "moment-timezone";
import { X } from "react-bootstrap-icons";

import AppButton from "../../common/buttons/app-button";
import ResponsiveWrapper from "../../common/ResponsiveWrapper";
import StarIcon from "../../../assets/icons-v2/result-popup/star-icon.png";
import PriceTagIcon from "../../../assets/icons-v2/price-tag-icon.png";
import useGAEvent from "../../../hooks/useGAEvent";
import useLocalStorage from "../../../hooks/useLocalStorage";

/**
 * A modal that displays the user's daily score and provides feedback based on the score.
 *
 * @component
 * @param {Object} props - Component properties.
 * @param {boolean} props.show - Controls the visibility of the modal.
 * @param {number} props.score - The user's score.
 * @param {string} props.actualPrice - The actual price of the property.
 * @param {Object} props.version - The version of live-variant.
 * @param {Function} props.handleClose - The function to close the modal.
 * @param {JSX.Element} props.puzzleResult - The component that displays the live variant puzzle result
 * @returns {JSX.Element}
 */
export default function DailyScoreModal({
    show,
    score,
    actualPrice,
    version,
    handleClose,
    handleShare,
    puzzleResult,
}) {
    const [timeRemaining, setTimeRemaining] = useState("Loading..."); // Default loading state
    const { sendEvent } = useGAEvent();
    const [visitorStatus] = useLocalStorage("visitor-status");
    const isScoreByTries = version?.score_by === "tries";
    const isNotSolved = isScoreByTries && score > version?.max_tries;
    const mailtoBase =
        "mailto:updates@priceme.app?subject=Sign%20me%20up%20for%20PriceMe%20Updates&body=Notify%20me%20each%20morning%20when%20the%20latest%20PriceMe%20is%20live!%0A%0A";
    const mailtoLink = `${mailtoBase}${version?.url}`;

    const getScoreFeedback = (score) => {
        if (isScoreByTries) {
            switch (score) {
                case 1:
                    return "Unbelievable!";
                case 2:
                    return "Uncanny!";
                case 3:
                    return "Wow! Strong performance!";
                case 4:
                    return "Well done";
                case 5:
                    return "Solid effort today";
                case 6:
                    return "Phew!";
                default:
                    return "So close! Try again tomorrow";
            }
        } else {
            if (score >= 10) return "Uncanny!";
            if (score >= 7) return "Wow! Strong Performance!";
            if (score >= 4) return "Solid effort today!";
            if (score >= 2) return "You’re making progress!";
            return "We all start somewhere!";
        }
    };

    const scoreFeedback = getScoreFeedback(score);

    /**
     * Handles the closing of the live-variant daily score modal.
     */
    const handleCloseModal = () => {
        sendEvent("daily_score_close", {
            userScore: score,
            liveVariantVersion: version?.gaVersion,
            visitorStatus: visitorStatus || "new",
        });
        handleClose();
    };

    const handleGetNotified = () => {
        sendEvent("next_priceme_notified", {
            userScore: score,
            liveVariantVersion: version?.gaVersion,
            visitorStatus: visitorStatus || "new",
        });
    };

    /**
     * Updates the countdown timer dynamically to count down to midnight CST.
     */
    useEffect(() => {
        const updateCountdown = () => {
            const now = moment().tz("America/Chicago");
            const midnight = moment().tz("America/Chicago").endOf("day");

            const diff = moment.duration(midnight.diff(now));
            const hours = diff.hours().toString().padStart(2, "0");
            const minutes = diff.minutes().toString().padStart(2, "0");
            const seconds = diff.seconds().toString().padStart(2, "0");

            setTimeRemaining(`${hours}hrs, ${minutes}m, ${seconds}s`);
        };

        updateCountdown(); // Initialize immediately
        const interval = setInterval(updateCountdown, 1000);
        return () => clearInterval(interval); // Clean up the interval on component unmount
    }, []);

    useEffect(() => {
        sendEvent("daily_score_view", {
            userScore: score,
            liveVariantVersion: version?.gaVersion,
            visitorStatus,
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <ResponsiveWrapper
            centered
            backdrop="static"
            data-cy="live-variant-daily-score-modal"
            keyboard={false}
            show={show}
            onHide={handleCloseModal}
            className="select-location-modal"
        >
            <div className="tw-flex tw-justify-end tw-pt-2 tw-px-2">
                <X size={30} onClick={handleCloseModal} />
            </div>
            <Modal.Body className="!tw-p-8 tw-text-center tw-flex tw-flex-col tw-items-center tw-gap-6">
                <div className="tw-text-center tw-flex tw-flex-col tw-items-center">
                    <p className="!tw-font-merriweather !tw-font-bold !tw-text-[21px] tw-mb-0">
                        {scoreFeedback}
                    </p>
                    <img className="tw-w-[82px] tw-my-2" src={StarIcon} alt="star-icon" />
                    {(version.modal?.show_actual_price || isNotSolved) && (
                        <>
                            <p className="tw-text-base tw-text-[#666666] tw-mb-0">Actual Price:</p>
                            <p className="!tw-text-xl !tw-font-semibold">{actualPrice}</p>
                        </>
                    )}
                </div>

                <div>
                    {!version.modal?.hide_solved && (
                        <p className="tw-text-purple-901 !tw-text-2xl !tw-font-semibold">
                            {version.score_by === "tries"
                                ? isNotSolved
                                    ? "Not solved"
                                    : `Solved in ${score}`
                                : `Your score: ${score}`}
                        </p>
                    )}
                    {puzzleResult && (
                        <div className="tw-bg-[#2E2E2E] tw-py-4 tw-px-8 tw-rounded-[10px] tw-mb-2">
                            {puzzleResult}
                        </div>
                    )}
                    <AppButton
                        variant="solid"
                        theme="gold"
                        className="modal-button !tw-w-[200px] !tw-mt-0"
                        onClick={handleShare}
                    >
                        Share Score
                    </AppButton>
                </div>

                <div>
                    <p className="tw-text-base tw-text-[#27273B] tw-mb-0 tw-mt-4">
                        The next PriceMe will be available in
                    </p>
                    <p className="!tw-text-xl !tw-font-bold !tw-font-merriweather tw-mb-0">{timeRemaining}</p>
                    <div className="tw-flex tw-justify-center tw-items-center tw-gap-1">
                        <img className="tw-w-[30px] tw-my-2" src={PriceTagIcon} alt="star-icon" />
                        {/* Get notified mailto link */}
                        <a
                            href={mailtoLink}
                            className="!tw-text-[#3B166A] tw-text-base !tw-font-medium"
                            onClick={handleGetNotified}
                        >
                            Get notified
                        </a>
                    </div>
                </div>
            </Modal.Body>
        </ResponsiveWrapper>
    );
}

DailyScoreModal.propTypes = {
    show: PropTypes.bool.isRequired,
    score: PropTypes.number.isRequired,
    actualPrice: PropTypes.string.isRequired,
    version: PropTypes.object.isRequired,
    property: PropTypes.object.isRequired,
    handleClose: PropTypes.func.isRequired,
    puzzleResult: PropTypes.element,
};
