import React, { useRef, useState, useCallback, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { useQuery } from "@tanstack/react-query";
import { get, inRange, isEmpty, find, isEqual } from "lodash";
import { useSwipeable } from "react-swipeable";
import Sticky from "react-sticky-el";
import MoonLoader from "react-spinners/MoonLoader";

import PropertyInfo from "../../../../components/common/PropertyInfo";
import { LIVE_VARIANT_VERSIONS } from "../../../../constants/live-variant-versions";
import { getLiveVariantDailyProperty } from "../../../../services/PropertyService";
import useGAEvent from "../../../../hooks/useGAEvent";
import useLocalStorage from "../../../../hooks/useLocalStorage";
import useModalState from "../../../../hooks/useModalState";

import { IoChevronUp } from "react-icons/io5";
import { motion } from "framer-motion";
import TileRow from "./TileRow";
import { formatPrice, pxToRem } from "../../../../helpers/helpers";
import CustomButton from "../../../../components/common/buttons/custom-button/custom-button";

import DailyScoreModal from "../../../../components/modals/LiveVariant/DailyScoreModal";
import config from "../../../../config";
import moment from "moment";
import { triggerShare } from "../../../../helpers/triggerShare";
import { isMobile } from "react-device-detect";
import useVisualViewport from "../../../../hooks/useVisualViewport";

const MAX_DIGIT = 8;
const MAX_GUESS = 6;
const INCORRECT_THRESHOLD = 2;
const INITIAL_GUESS_INPUT_HEIGHT = 4;

const GAME_STATES = Object.freeze({ ONGOING: "ONGOING", LOSE: "LOSE", WIN: "WIN" });

const scoreEmojis = {
    correct: "🟪",
    incorrect: "⬜",
    close: "🟧",
};

export default function Puzzle({ version }) {
    const { sendEvent } = useGAEvent();
    const viewport = useVisualViewport();
    const bodyRef = useRef(null);
    const inputRef = useRef(null);
    const [property, setProperty] = useState(null);
    const [guesses, setGuesses] = useState(Array(MAX_GUESS).fill(null));
    const [currentIndex, setCurrentIndex] = useState(0);
    const [expand, setExpand] = useState(false);
    const [inputFocused, setInputFocused] = useState(false);
    const [showModal, setShowModal] = useState("");
    const [gameState, setGameState] = useState(GAME_STATES.ONGOING);
    const [ongoingAnimation, setOngoingAnimation] = useState("idle");
    const [guessInputHeight, setGuessInputHeight] = useState();
    const currentVersion = LIVE_VARIANT_VERSIONS[version];
    const { setModalId } = useModalState();
    const [shownHelpModals, setShownHelpModal] = useLocalStorage("shown-help-modals", []);
    const [guessedProperties, setGuessedProperties] = useLocalStorage("guessed_properties", []);
    const [guessedPropertyResults, setGuessedPropertyResults] = useLocalStorage(
        "guessed_property_results",
        []
    );
    const [visitorStatus] = useLocalStorage("visitor-status");
    const guessedResult = find(
        guessedPropertyResults,
        (result) => result.property.mls_id === property?.mls_id && result?.version === version
    );
    const virtualKeyboardShown = inputFocused && isMobile;

    // Check if this property instance has already been guessed
    const isPropertyGuessed = guessedProperties?.includes(property?.mls_id) && guessedResult;

    const dailyProperties = useQuery({
        queryKey: ["live-variant-daily-property"],
        queryFn: getLiveVariantDailyProperty,
        enabled: true,
        cacheTime: 0,
        onSuccess: (data) => {
            const property = get(data, "data.properties", null);
            if (property?.price) {
                setProperty(property);
            }
        },
    });

    const isLoading = dailyProperties?.isLoading || dailyProperties?.isFetching || !property;
    const actualPriceValue = property?.price;
    const actualPriceSplit = useMemo(() => {
        return actualPriceValue ? actualPriceValue.toString().padStart(MAX_DIGIT, "0").split("") : [];
    }, [actualPriceValue]);

    const correctGuessIndex = guesses.findIndex((guess) => +guess === actualPriceValue);

    const checkTileMode = useCallback(
        (rowIndex, tileIndex, tileValue) => {
            if (rowIndex === currentIndex || isEmpty(tileValue)) {
                return "default";
            }

            const correctTileValue = +actualPriceSplit[tileIndex];

            if (correctTileValue === +tileValue) {
                return "correct";
            }

            const inThreshold = inRange(
                +tileValue,
                correctTileValue - INCORRECT_THRESHOLD,
                correctTileValue + INCORRECT_THRESHOLD + 1
            );

            return inThreshold ? "close" : "incorrect";
        },
        [actualPriceSplit, currentIndex] // eslint-disable-line react-hooks/exhaustive-deps
    );

    const validGuesses = guesses.filter((guess) => !isEmpty(guess));
    const puzzleResult = validGuesses.map((guessValue, guessIndex) => {
        return (
            <div className="tw-text-white tw-tracking-[-2px]">
                {String(guessValue)
                    .split("")
                    .map(
                        (digitValue, digitIndex) =>
                            scoreEmojis[checkTileMode(guessIndex, digitIndex, digitValue)] +
                            (digitIndex === 1 || digitIndex === 4 ? "," : "")
                    )
                    .join("")}
            </div>
        );
    });

    const swipeHandlers = useSwipeable({
        onSwipedUp: () => !expand && setExpand(true),
        onSwipedDown: () => {
            // When swiping down on the expanded guess input section, remove focus from the input and set expand to false
            if (expand) {
                inputRef?.current?.blur();
                setExpand(false);
            }
        },
        preventDefaultTouchmoveEvent: true,
        trackMouse: true, // Optional: enables swipe events with mouse
    });

    const handleChange = (event) => {
        if (ongoingAnimation !== "idle" || gameState !== GAME_STATES.ONGOING) {
            event.preventDefault();

            return;
        }

        const value = event.target.value;

        setGuesses((prevGuesses) => {
            const newGuesses = [...prevGuesses];
            newGuesses[currentIndex] = value;
            return newGuesses;
        });
    };

    const handleEnterKey = (event) => {
        if (ongoingAnimation !== "idle" || gameState !== GAME_STATES.ONGOING) {
            event.preventDefault();

            return;
        }

        const currentGuessLength = guesses[currentIndex]?.length ?? 0;

        if (event.code === "Enter") {
            event.preventDefault();

            if (currentGuessLength === MAX_DIGIT) {
                handleSubmit(event);
            }
        }

        if (currentGuessLength >= MAX_DIGIT && event.code !== "Backspace") {
            event.preventDefault();
            return;
        }
    };

    const handleSubmit = (event) => {
        const isCorrect = +guesses[currentIndex] === actualPriceValue;
        const noMoreGuess = currentIndex + 1 === guesses.length;

        sendEvent("enter_guess", {
            liveVariantVersion: currentVersion?.gaVersion,
            visitorStatus: visitorStatus || "new",
        });

        // Set the ongoing animation to "transit" to prep before the next animation so that this is not "idle"
        setOngoingAnimation("transit");

        if (isCorrect) {
            setGameState(GAME_STATES.WIN);
        }

        if (noMoreGuess) {
            setGameState(GAME_STATES.LOSE);
        }

        setCurrentIndex((prevIndex) => prevIndex + 1);
        event.target.value = "";
    };

    const handleShare = async () => {
        sendEvent("daily_score_share_score", {
            userScore: validGuesses.length ?? 0,
            liveVariantVersion: currentVersion?.gaVersion,
            visitorStatus: visitorStatus || "new",
        });

        const currentDate = moment().tz("America/Chicago").format("MMM D, YYYY");
        const shareTextTitle = `PriceMe ${currentDate}\n`;
        const shareTextBody = validGuesses
            .map((guessValue, guessIndex) => {
                return String(guessValue)
                    .split("")
                    .map(
                        (digitValue, digitIndex) =>
                            scoreEmojis[checkTileMode(guessIndex, digitIndex, digitValue)] +
                            (digitIndex === 1 || digitIndex === 4 ? "," : "")
                    )
                    .join("");
            })
            .join("\n");
        const shareUrl = `${config.LIVE_VARIANT_BASE_URL}/${currentVersion.url}?medium=share`;

        await triggerShare({
            web: {
                text: `${shareTextTitle}${shareTextBody}\n\n${shareUrl}`,
            },
            mobile: {
                title: shareTextTitle,
                text: shareTextBody,
                url: shareUrl,
            },
        });
    };

    useEffect(() => {
        // Show the modal if the game state has been updated from "ONGOING" and there's no ongoing animation ("idle")
        if (gameState !== GAME_STATES.ONGOING && ongoingAnimation === "idle") {
            if (!guessedProperties?.includes(property?.mls_id)) {
                setGuessedProperties([...guessedProperties, property.mls_id]);
            }

            // Store the result in guessed_property_results
            setGuessedPropertyResults([
                ...guessedPropertyResults,
                { property, guesses, currentIndex, version },
            ]);

            setShowModal("dailyscore");
        }
    }, [gameState, ongoingAnimation]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (isPropertyGuessed && guessedResult?.guesses && !isEqual(guesses, guessedResult.guesses)) {
            setGuesses(guessedResult.guesses);
            setCurrentIndex(guessedResult.currentIndex);
        }
    }, [guessedResult?.guesses, isPropertyGuessed]); // eslint-disable-line react-hooks/exhaustive-deps

    // Toggle the scrolling if the guess input is focused or blurred
    useEffect(() => {
        if (inputFocused) {
            document.body.style.overflow = "hidden";

            if (isMobile) {
                document.body.style.touchAction = "none";
            }
        } else {
            document.body.style.overflow = "";

            if (isMobile) {
                document.body.style.touchAction = "";
            }
        }
    }, [inputFocused]);

    useEffect(() => {
        if (!dailyProperties?.isLoading && !dailyProperties?.isFetching && !property) {
            // try to refetch when property is not yet set
            dailyProperties.refetch();
        }
    }, [property]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!isLoading && !shownHelpModals?.includes(version)) {
            setShownHelpModal([...shownHelpModals, version]);
            setModalId("LIVE_VARIANT_HELP_MODAL");
        }
    }, [isLoading, shownHelpModals]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        // There is a weird delay in the viewport resize event in IOS when the virtual keyboard is opened after the page load
        // This delay goes away when the user scrolls the page
        // https://stackoverflow.com/questions/72747030/delay-on-resize-event-on-virtual-keyboard-on-ios-safari
        setGuessInputHeight(
            expand
                ? (isPropertyGuessed ? 22 : 19) +
                      0.5 +
                      (virtualKeyboardShown ? pxToRem(viewport.offsetHeight) : 0)
                : INITIAL_GUESS_INPUT_HEIGHT
        );
    }, [viewport?.offsetHeight, expand, isPropertyGuessed]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (property) {
            sendEvent("start_page_load", {
                liveVariantVersion: currentVersion?.gaVersion,
                visitorStatus: visitorStatus || "new",
            });
        }
    }, [property]); // eslint-disable-line react-hooks/exhaustive-deps

    if (dailyProperties?.error) {
        return (
            <div className="tw-flex tw-items-center tw-justify-center tw-h-3/4 tw-p-8">
                {dailyProperties.error.response?.data?.message ||
                    "Failed to load daily property. Please try again later."}
            </div>
        );
    }

    if (isLoading) {
        return (
            <div className="tw-flex tw-items-center tw-justify-center tw-h-3/4">
                <MoonLoader size={40} color="#63c19f" />
            </div>
        );
    }

    return (
        <div className="property-container" ref={bodyRef}>
            <PropertyInfo property={property} />
            <Sticky
                className="tw-sticky tw-bottom-0 tw-z-[999]"
                mode="bottom"
                positionRecheckInterval={expand ? 50 : 2}
                onClick={() => setExpand(true)}
            >
                <motion.div
                    className="guess-input-container overflow-hidden px-3 pt-0 pb-3 guess-input-container-shadow"
                    {...swipeHandlers} // Attach swipe handlers here
                    initial={{ height: `${INITIAL_GUESS_INPUT_HEIGHT}rem` }}
                    animate={{
                        height: `${guessInputHeight}rem`,
                    }}
                    transition={{
                        height: {
                            ease: "easeOut",
                            duration: 0.5,
                            delay: virtualKeyboardShown ? 0 : 0.1,
                        },
                    }}
                >
                    <div className="flex-wrap d-flex align-items-center justify-content-center">
                        <motion.div
                            className="score-to-beat mw-100 px-4"
                            initial={false}
                            animate={{ rotate: expand ? 180 : 0 }}
                            transition={{ ease: "easeOut", duration: 0.5, delay: 0.1 }}
                        >
                            <IoChevronUp
                                className="hover:tw-cursor-pointer"
                                fontSize={25}
                                onClick={(e) => {
                                    // We need to stop the propagation to the Sticky component's onClick handler if expanded
                                    if (expand) {
                                        e.stopPropagation();
                                    }

                                    setExpand(!expand);
                                }}
                            />
                        </motion.div>
                    </div>
                    <p className="tw-font-bold tw-text-center">
                        {expand
                            ? "Hide guesses"
                            : `Swipe up to ${!isPropertyGuessed ? "guess the price!" : "review your game"}`}
                    </p>
                    <div className="tw-flex tw-flex-row tw-flex-wrap tw-items-center tw-justify-center tw-gap-1">
                        {guesses.map((guess, index) => (
                            <TileRow
                                key={`tile-row-${index}`}
                                expand={expand}
                                length={MAX_DIGIT}
                                rowIndex={index}
                                value={guess}
                                gameIndex={currentIndex}
                                checkTileMode={checkTileMode}
                                inputRef={inputRef}
                                handleSubmit={handleSubmit}
                                setOngoingAnimation={setOngoingAnimation}
                                isPropertyGuessed={isPropertyGuessed}
                            />
                        ))}

                        <input
                            type="number"
                            inputMode="numeric"
                            className="tw-opacity-0 tw-fixed tw--left-[2000px]"
                            disabled={isPropertyGuessed || gameState !== GAME_STATES.ONGOING}
                            ref={inputRef}
                            value={guesses[currentIndex] || ""}
                            maxLength={MAX_DIGIT}
                            onChange={handleChange}
                            onKeyDown={handleEnterKey}
                            onFocus={() => setInputFocused(true)}
                            onBlur={() => setInputFocused(false)}
                        />
                    </div>

                    {isPropertyGuessed && expand && (
                        <div className="tw-flex tw-gap-4 !tw-pt-2 !tw-px-4">
                            <CustomButton
                                className="tw-w-1/2 tw-flex tw-items-center tw-justify-center purple-outlined tw-rounded-500px"
                                handleClick={() => setShowModal("dailyscore")}
                                text="Done"
                            />
                            <CustomButton
                                className="tw-w-1/2 tw-flex tw-items-center tw-justify-center gold-solid tw-rounded-500px"
                                handleClick={() => handleShare()}
                                text="Share Score"
                            />
                        </div>
                    )}
                </motion.div>
            </Sticky>
            {showModal === "dailyscore" && (
                <DailyScoreModal
                    show={showModal === "dailyscore"}
                    handleClose={() => setShowModal("done")}
                    score={(correctGuessIndex !== -1 ? correctGuessIndex : guesses.length) + 1}
                    actualPrice={formatPrice(actualPriceValue)}
                    version={currentVersion}
                    property={property}
                    handleShare={handleShare}
                    puzzleResult={puzzleResult}
                />
            )}
        </div>
    );
}

Puzzle.propTypes = {
    version: PropTypes.string.isRequired,
};
