import React, { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import PropTypes from "prop-types";

const NumberRoller = ({ targetNumber }) => {
    const [currentNumber, setCurrentNumber] = useState(targetNumber);

    useEffect(() => {
        if (targetNumber !== currentNumber) {
            setCurrentNumber(targetNumber);
        }
    }, [targetNumber, currentNumber]);

    const variants = {
        initial: { y: 10, opacity: 0 }, // New number enters from above
        animate: { y: 0, opacity: 1, transition: { duration: 0.25, type: "spring" } }, // New number moves into place
        exit: { y: -10, opacity: 0, transition: { duration: 0.25 } }, // Current number exits downwards
    };

    return (
        <div className="tw-relative tw-inline-block tw-h-[1rem] tw-w-full tw-leading-[1rem] tw-overflow-hidden">
            <AnimatePresence mode="wait">
                <motion.div
                    className="tw-absolute tw-w-full"
                    key={currentNumber} // Key should be unique to the current number
                    initial="initial"
                    animate="animate"
                    exit="exit"
                    variants={variants}
                >
                    {currentNumber}
                </motion.div>
            </AnimatePresence>
        </div>
    );
};

NumberRoller.propTypes = {
    targetNumber: PropTypes.number.isRequired,
};

export default NumberRoller;
