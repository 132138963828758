import { AddToHomeScreenCta } from "../pages/leaderboard/components";
import AwardsModal from "../components/modals/awards-modal/awards-modal";
import BonusEarnedModal from "../components/modals/bonus-earned-modal";
import BonusPropertyModal from "../components/modals/bonus-property-modal";
import ChallengedModal from "../components/modals/ChallengedModal";
import LiveVariantHelpModal from "../components/modals/LiveVariant/HelpModal";
import LiveVariantSelectLocationModal from "../components/modals/LiveVariant/SelectLocationModal";
import SelectLocationModal from "../components/modals/select-location-modal/select-location-modal";
import StartupModal from "../components/modals/startup-modal/startup-modal";
import WarningModal from "../components/modals/warning-modal/warning-modal";

export const GLOBAL_MODALS = [
    {
        key: "ADD_TO_HOME_SCREEN_MODAL",
        component: <AddToHomeScreenCta />,
    },
    {
        key: "AWARDS_MODAL",
        component: <AwardsModal />,
    },
    {
        key: "BONUS_EARNED_MODAL",
        component: <BonusEarnedModal />,
    },
    {
        key: "BONUS_PROPERTY_MODAL",
        component: <BonusPropertyModal />,
    },
    {
        key: "CHALLENGED_MODAL",
        component: <ChallengedModal />,
    },
    {
        key: "LIVE_VARIANT_HELP_MODAL",
        component: <LiveVariantHelpModal />,
    },
    {
        key: "LIVE_VARIANT_SELECT_LOCATION_MODAL",
        component: <LiveVariantSelectLocationModal />,
    },
    {
        key: "SELECT_LOCATION_MODAL",
        component: <SelectLocationModal />,
    },
    {
        key: "STARTUP_MODAL",
        component: <StartupModal />,
    },
    {
        key: "WARNING_MODAL",
        component: <WarningModal />,
    },
];
