import PropTypes from "prop-types";
import toast from "react-hot-toast";
import { useAuth0 } from "@auth0/auth0-react";
import { useUser } from "../../../../context/UserContext";
import { isNil } from "lodash";
import { useEffect, useMemo } from "react";
import useLogout from "../../../../hooks/useLogout";
import { useQuery } from "@tanstack/react-query";
import { getUserGuessesPerPeriod } from "../../../../services/UserService";
import { getGuestPlaysToday } from "../../../../helpers/helpers";
import { useGuestContext } from "../../../../context/GuestContext";
import useRegisterCtaModal from "../../../../hooks/useRegisterCtaModal";
import useCustomLoginWithRedirect from "../../../../hooks/useCustomLoginWithRedirect";
import config from "../../../../config";
import CustomSvg from "../../custom-svg/custom-svg";
import LockedIcon from "../../../../assets/icons-v2/lock.png";
import useGAEvent from "../../../../hooks/useGAEvent";
import { triggerShare } from "../../../../helpers/triggerShare";
import useModalState from "../../../../hooks/useModalState";

const SideMenuItem = (props) => {
    const { customLoginWithRedirect } = useCustomLoginWithRedirect();
    const { handleRegister } = useRegisterCtaModal({ delay: null });
    const { guestPlay } = useGuestContext();
    const { isAuthenticated } = useAuth0();
    const { logoutUser } = useLogout();
    const { sendEvent } = useGAEvent();
    const { setModalId } = useModalState();
    const { hasFetchedData, isInvalidLogin, userState, clearUserState, fetchUserData, setInvalidLogin } =
        useUser();

    const userGuesses = useQuery({
        queryKey: ["user-guesses", userState?.id],
        queryFn: () =>
            getUserGuessesPerPeriod({
                user_id: userState?.id,
            }),
        cacheTime: 0,
        enabled: isAuthenticated && !!userState?.id,
        placeholderData: () => {
            const guestPlayToday = getGuestPlaysToday(guestPlay, userState);

            return {
                data: {
                    count: {
                        total: isNil(guestPlayToday?.length) ? 0 : guestPlayToday?.length,
                    },
                },
            };
        },
    });

    /**
     * React hook to fetch user data when the component mounts and the user is authenticated.
     *
     * @param {boolean} isAuthenticated - Indicates if the user is authenticated (logged in).
     * @param {Object|null} userState - The user object stored in the User context. Can be null if no user data is available.
     * @param {boolean} hasFetchedData - Indicates if the user data has been fetched from the server.
     * @param {Function} fetchUserData - The function to fetch user data.
     */
    useEffect(() => {
        if (isAuthenticated && !isNil(userState) && hasFetchedData) {
            fetchUserData(userState.id);
        }
    }, [isAuthenticated]); // eslint-disable-line react-hooks/exhaustive-deps

    /**
     * React hook to handle invalid login scenarios.
     * If the user is authenticated but has an invalid login (`isInvalidLogin` is true), it logs an error message and initiates a logout process after a 3-second delay.
     *
     * @todo - Move this to a middleware when we use Redux
     * @param {boolean} isAuthenticated - Indicates if the user is authenticated (logged in).
     * @param {boolean} isInvalidLogin - Indicates if the login is invalid (user object in the User context is missing or invalid).
     * @param {Function} logout - The function to initiate the logout process.
     */
    useMemo(() => {
        if (isAuthenticated && isInvalidLogin) {
            setTimeout(() => {
                setInvalidLogin(false);
                logoutUser();
            }, 3000);
        }
    }, [isInvalidLogin, logoutUser]); // eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Checks if the given label corresponds to the 'Switch Location' menu item and if the player has already guessed.
     *
     * @param {string} label - The label or name of the menu item.
     *
     * @returns {boolean} Returns true if the label is 'Switch Location' and the player has already guessed; otherwise, returns false.
     */
    const isLockedSwitchLocation = (label) => {
        return userGuesses?.data?.data?.count?.daily > 0 && label === "Switch Location";
    };

    /**
     * Handles the click event on a side menu item.
     *
     * @param {string} menuItem.label - The label or name of the menu item.
     * @param {string} menuItem.url - The URL to navigate when the menu item is clicked.
     */
    const handleSideMenuItemClick = async ({ label, url }) => {
        if (label === "Sign In" && !isAuthenticated) {
            handleRegister(() =>
                customLoginWithRedirect({
                    authorizationParams: {
                        redirect_uri: window.location.origin + "/",
                    },
                    appState: {
                        toggleProfile: true,
                    },
                })
            );

            return;
        } else if (label === "My Profile") {
            props.onProfileToggle();

            return;
        } else if (label === "Sign Out") {
            clearUserState();

            logoutUser();

            return;
        } else if (label === "Invite a friend") {
            sendEvent("invite_friend_menu", {
                isAuthenticated,
                referral_code: localStorage.getItem("referral_code"),
            });

            // Trigger the share functionality for web and mobile devices
            await triggerShare({
                web: {
                    text: `${config.BASE_URL}/?ref=${userState?.referral_code}`,
                },
                mobile: {
                    title: "PriceMe",
                    text: "Check out PriceMe! It's a home price guessing game. It shows you homes that recently sold near you, and you guess the price:",
                    url: `${config.BASE_URL}/?ref=${userState?.referral_code}`,
                },
            });

            return props.onClose();
        } else if (label === "Switch Location") {
            if (isLockedSwitchLocation(label)) {
                toast.error("You've already started playing today!");
            } else {
                setModalId("SELECT_LOCATION_MODAL");
            }

            return props.onClose();
        }

        if (label === "Choose Area" || label === "Switch Area") {
            // setModalId("LIVE_VARIANT_SELECT_LOCATION_MODAL");
            return props.onClose();
        }

        window.location.href = url;
    };

    if (props.item.label === "Invite a friend" && !isAuthenticated) {
        return null;
    }

    return (
        <li className="tw-flex" onClick={() => handleSideMenuItemClick(props.item)}>
            {isLockedSwitchLocation(props.item.label) ? (
                <CustomSvg src={LockedIcon} size={{ width: 27, height: 27 }} />
            ) : (
                props.item.icon
            )}{" "}
            &nbsp;&nbsp;
            {props.item.label}
        </li>
    );
};

SideMenuItem.propTypes = {
    item: PropTypes.shape({
        label: PropTypes.string.isRequired,
        url: PropTypes.string,
        icon: PropTypes.element.isRequired,
        isRegistered: PropTypes.bool,
    }),
};

export default SideMenuItem;
