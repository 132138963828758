import React from "react";
import useModalState from "../../../hooks/useModalState";
import { GLOBAL_MODALS } from "../../../constants/global-modals";

/**
 * NEW GLOBAL MODALS SHOULD BE ADDED HERE
 * USE useModalState HOOK FOR GLOBAL MODALS
 */

const RootModals = () => {
    const { isOpen } = useModalState();

    return <>{GLOBAL_MODALS.map((modal) => isOpen(modal.key) && modal.component)}</>;
};

export default RootModals;
