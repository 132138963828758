import React from "react";
import Modal from "react-bootstrap/Modal";
import { find } from "lodash";
import { X } from "react-bootstrap-icons";

import useModalState from "../../../../hooks/useModalState";
import useLocalStorage from "../../../../hooks/useLocalStorage";
import { LIVE_VARIANT_VERSIONS } from "../../../../constants/live-variant-versions";

export default function HelpModal() {
    const { setModalId, isOpen } = useModalState();
    const [preferredVersion] = useLocalStorage("live-variant-preferred-version");
    const currentVersion = find(LIVE_VARIANT_VERSIONS, (version) => version.url === preferredVersion);
    const modalBody = currentVersion?.modal?.body;

    /**
     * Handles the closing of the live-variant help modal.
     */
    const handleCloseModal = () => {
        setModalId("");
    };

    return (
        <Modal
            centered
            backdrop="static"
            data-cy="live-variant-help-modal"
            keyboard={false}
            show={isOpen("LIVE_VARIANT_HELP_MODAL")}
            onHide={handleCloseModal}
            className="select-location-modal"
        >
            <div className="tw-flex tw-justify-end tw-pt-2 tw-px-2">
                <X size={30} onClick={handleCloseModal} />
            </div>
            {modalBody}
        </Modal>
    );
}
