import React from "react";
import Modal from "react-bootstrap/Modal";

import HouseSoldIcon from "../../../../../assets/icons-v2/house-sold-icon.png";
import CorrectGuess from "../../../../../assets/images/live-variant/sample_guess _correct.png";
import CloseGuess from "../../../../../assets/images/live-variant/sample_guess _close.png";
import WrongGuess from "../../../../../assets/images/live-variant/sample_guess _wrong.png";

export default function PuzzleModalBody() {
    return (
        <Modal.Body className="!tw-pt-0 !tw-px-6 tw-text-center tw-flex tw-flex-col tw-items-center tw-text-base tw-text-gray-902">
            <p className="!tw-font-merriweather !tw-font-bold !tw-text-2xl tw-mb-1 !tw--mt-4 tw-text-black">
                How to play
            </p>
            <p>Guess the Price in 6 tries</p>
            <img className="tw-w-[100px] tw-h-[65px] tw-my-2" src={HouseSoldIcon} alt="priceme-logo" />
            <p className="tw-w-full tw-my-2 tw-px-1">
                We pick one wild, weird or interesting property every day. You get 6 tries to guess the price.
            </p>
            <p className="!tw-font-merriweather !tw-font-bold !tw-text-[18px] tw-w-full tw-my-2 !tw-text-left tw-text-black">
                Examples
            </p>
            <img className="tw-w-full tw-my-1" src={CorrectGuess} alt="correct-guess" />
            <p>5 is the correct digit</p>
            <img className="tw-w-full tw-my-1" src={CloseGuess} alt="close-guess" />
            <p>3 is within 2 of the correct digit, so it is either 1, 2, 4 or 5.</p>
            <img className="tw-w-full tw-my-1" src={WrongGuess} alt="wrong-guess" />
            <p className="tw-px-1">7 is more than 2 off of the correct digit</p>
        </Modal.Body>
    );
}
