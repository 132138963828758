import { isNil } from "lodash";
import { useUser } from "../context/UserContext";

const useGAEvent = () => {
    const { userState } = useUser();
    const referral_code = localStorage.getItem("referral_code");
    const eventData = {};

    /**
     * Sends an event to Google Analytics with additional information.
     *
     * This function constructs the event data based on the provided information
     * such as user authentication status, referral code, user area, and final score.
     *
     * @param {string} eventName - The name of the event to be sent to Google Analytics.
     * @param {object} additionalInfo - Additional information for constructing the event data.
     * @param {boolean} additionalInfo.isAuthenticated - The authentication status of the user.
     * @param {string} additionalInfo.referralCode - The referral code associated with the user.
     * @param {string} additionalInfo.userArea - The area of the user.
     * @param {number} additionalInfo.finalScore - The final score of the user.
     * @param {number} additionalInfo.userScore - The score of the user in a particular session.
     * @param {number} additionalInfo.practiceFinalScore - The final score of the user in practice.
     * @param {number} additionalInfo.userRank - The rank of the user.
     * @param {string} additionalInfo.mlsId - The MLS ID associated with the user.
     * @param {string} additionalInfo.buttonLabel - The label of the button that was clicked.
     * @param {number} additionalInfo.numberOfGuesses - The number of guesses made by the user.
     * @param {string} additionalInfo.challengeId - The ID of the challenge.
     * @param {string} additionalInfo.challengeStatus - The status of the challenge.
     * @param {string} additionalInfo.userLocation - The location of the user.
     * @param {number} additionalInfo.guestFinalScoreModalVersion - The modal version loaded in the guest final score modal.
     *
     * @returns {void}
     *
     * @example
     * // Example usage:
     * sendEvent("button_click", {
     *    isAuthenticated: true,
     *    referralCode: "ABC123",
     *    userArea: "Chicago",
     *    finalScore: 150,
     *    userScore: 100,
     *    practiceFinalScore: 80,
     *    userRank: 5,
     *    mlsId: "MLS123456",
     *    buttonLabel: "Keep Playing",
     *    numberOfGuesses: 3,
     *    challengeId: "CH123456",
     *    challengeStatus: "completed",
     *    userLocation: "New York"
     * });
     */
    const sendEvent = (eventName, additionalInfo) => {
        const {
            isAuthenticated,
            referralCode,
            userArea,
            finalScore,
            userScore,
            practiceFinalScore,
            userRank,
            mlsId,
            buttonLabel,
            numberOfGuesses,
            challengeId,
            challengeStatus,
            challengeGuestRegisterModalVersion,
            userLocation,
            guestFinalScoreModalVersion,
            guestChallengeRedirect,
            challengeRegisterButton,
            plusMinusGuess,
            plusMinusCorrect,
            plusMinusVersion,
            liveVariantVersion,
            propertyMode,
            visitorStatus,
            newVersion,
        } = additionalInfo;

        if (window) {
            if (!isNil(isAuthenticated)) {
                let userStatus = null;

                if (isAuthenticated) {
                    userStatus = "registered";
                } else {
                    if (!isNil(referral_code) || !isNil(referralCode)) {
                        userStatus = "guest_referral";
                    } else {
                        userStatus = "guest";
                    }
                }

                eventData["user_status"] = userStatus;
            }

            if (!isNil(userArea)) {
                eventData["user_area"] = userArea;
            }

            if (!isNil(finalScore)) {
                eventData["user_final_score"] = finalScore ?? 0;
            }

            if (!isNil(practiceFinalScore)) {
                eventData["practice_final_score"] = practiceFinalScore ?? 0;
            }

            if (!isNil(userScore)) {
                eventData["user_score"] = userScore ?? 0;
            }

            if (!isNil(userRank)) {
                eventData["user_rank"] = userState?.show_score_leaderboard ? userRank ?? 0 : "Hidden";
            }

            if (!isNil(userLocation)) {
                eventData["user_location"] = userLocation;
            }

            if (!isNil(mlsId)) {
                eventData["mls_id"] = mlsId;
            }

            if (!isNil(buttonLabel)) {
                eventData["button_label"] = buttonLabel;
            }

            if (!isNil(numberOfGuesses)) {
                eventData["num_guesses"] = numberOfGuesses ?? 0;
            }

            if (!isNil(challengeId)) {
                eventData["challenge_id"] = challengeId ?? 0;
            }

            if (!isNil(challengeGuestRegisterModalVersion)) {
                eventData["challenge_guest_register_modal_version"] = challengeGuestRegisterModalVersion;
            }

            if (!isNil(challengeStatus)) {
                eventData["challenge_status"] = challengeStatus;
            }

            if (!isNil(guestFinalScoreModalVersion)) {
                eventData["guest_final_score_modal_version"] = guestFinalScoreModalVersion;
            }

            if (!isNil(guestChallengeRedirect)) {
                eventData["guest_challenge_redirect"] = guestChallengeRedirect;
            }

            if (!isNil(challengeRegisterButton)) {
                eventData["challenge_register_button"] = challengeRegisterButton;
            }

            if (!isNil(plusMinusGuess)) {
                eventData["guess"] = plusMinusGuess;
            }

            if (!isNil(propertyMode)) {
                eventData["property_mode"] = propertyMode;
            }

            if (!isNil(plusMinusCorrect)) {
                eventData["correct"] = plusMinusCorrect;
            }

            if (!isNil(liveVariantVersion) || !isNil(plusMinusVersion)) {
                eventData["version"] = liveVariantVersion || plusMinusVersion;
            }

            if (!isNil(newVersion)) {
                eventData["new_version"] = newVersion;
            }

            if (!isNil(visitorStatus)) {
                eventData["visitor_status"] = visitorStatus;
            }

            window.gtag("event", eventName, eventData);
        }
    };

    return { sendEvent };
};

export default useGAEvent;
