import { useState } from "react";
import CheckmarkConfetti from "./CheckmarkConfetti";

const AnimationTest = () => {
    const [showConfetti, setShowConfetti] = useState(false);

    return (
        <div className="tw-flex tw-justify-center tw-pt-4">
            <button onClick={() => setShowConfetti(true)}>Checkmark Confetti</button>

            <CheckmarkConfetti play={showConfetti} setPlay={setShowConfetti} />
        </div>
    );
};

export default AnimationTest;
