import React from "react";
import Modal from "react-bootstrap/Modal";

import HouseSoldIcon from "../../../../../assets/icons-v2/house-sold-icon.png";
import OverUnderIcon from "../../../../../assets/icons-v2/over-under.png";

export default function OverUnderModalBody() {
    return (
        <Modal.Body className="!tw-pt-0 !tw-px-6 tw-text-center tw-flex tw-flex-col tw-items-center tw-text-base tw-text-gray-902">
            <p className="!tw-font-merriweather !tw-font-bold !tw-text-2xl tw-mb-1">Guess the price!</p>
            <p>The rules are simple...</p>
            <img className="tw-w-[140px] tw-h-[91px] tw-my-4" src={HouseSoldIcon} alt="priceme-logo" />
            <p>We pick one wild, weird or interesting property every day... and give you a fake price</p>
            <img className="tw-w-[90px] tw-my-6" src={OverUnderIcon} alt="over-under-logo" />
            <p>
                Guess if the real price is higher or lower. If you’re correct, you score a point and get a new
                fake price to guess.
            </p>
            <p>Each round gets tougher and closer to the real price.</p>
        </Modal.Body>
    );
}
