import { DateTime } from "luxon";
import { filter, groupBy } from "lodash";
import moment from "moment";
import { isEmpty } from "lodash";
import { isNull } from "lodash";

import { triggerShare } from "./triggerShare";
import { createChallenge } from "../services/ChallengeService";
import config from "../config";
/**
 * Unmasks a masked value by removing leading dollar sign ($) and comma (,) characters.
 *
 * @param {string} maskedValue - The masked value to be unmasked.
 * @returns {string} The unmasked value.
 */
export const unmaskValue = (maskedValue) => {
    // If the masked value is a number, parse it as a string
    if (typeof maskedValue === "number") {
        maskedValue = maskedValue.toFixed(0);
    }

    return maskedValue.replace(/^\$|,/g, "");
};

/**
 * Returns the day number based on the given date.
 * If no date is given, use the current date.
 * (0 - Monday, ... 6 - Sunday)
 *
 * @param {string | Date} dateStr - The date string or Date object.
 * @returns {number} - The day number of the week.
 */
export const getDayNumber = (dateStr) => {
    let intDayNumber;

    if (!dateStr) {
        dateStr = new Date();
    }

    intDayNumber = dateStr.getDay() - 1;

    return intDayNumber >= 0 ? intDayNumber : 6;
};
/**
 * Checks if value is 0
 * @param {any} val - The value to be checked.
 */
export const isZero = (val) => {
    if (!val) {
        return true;
    }

    if (isNaN(val)) {
        return true;
    }

    const parsed = parseInt(val);

    if (!parsed) {
        return true;
    }

    return false;
};

/**
 * Formats rank
 * @param {object} rank - The rank query response.
 */
export const getRank = (rank) => {
    if (rank.current_rank && rank.total_rank) {
        return `${rank.current_rank} / ${rank.total_rank}`;
    }

    return "No rankings yet.";
};

/**
 * Utility function to get the start and end dates of the week that the given date falls in.
 *
 * @param {string} date - The date string in the format 'M/d/yyyy' for which to find the week's start and end dates.
 *
 * @returns {Object} An object containing the start and end dates of the week.
 * @property {string} startOfWeek - The start date of the week in the format 'yyyy-MM-dd'.
 * @property {string} endOfWeek - The end date of the week in the format 'yyyy-MM-dd'.
 */
export const getStartAndEndOfWeek = (date) => {
    const currentDate = DateTime.fromFormat(date, "M/d/yyyy");
    const startOfWeek = new Date(currentDate.startOf("week")).toLocaleDateString();
    const endOfWeek = new Date(currentDate.endOf("week")).toLocaleDateString();

    return {
        startOfWeek,
        endOfWeek,
    };
};

export const groupScoresByDate = (scores) => {
    return Object.entries(
        groupBy(
            scores?.map((el) => ({
                ...el,
                created_at: moment(el.created_at).format("M/DD/YYYY"),
            })),
            (el) => el.created_at
        )
    )?.map((el) => ({ period: el[0], dayName: moment(el[0]).format("d"), scores: el[1] }));
};

/**
 * Masks a string by revealing the first `showCharacterIndex` characters and masking the rest with asterisks (*).
 *
 * @param {string} inputString The input string to be masked.
 * @param {number} showCharacterIndex The index up to which characters should be revealed.
 *
 * @returns {string} The masked string.
 */
export const maskString = (inputString, showCharacterIndex) => {
    // Check if the input string has at least two characters
    if (inputString.length < 2) {
        return "**";
    }

    // Extract the first two characters from the input string
    const firstTwoChars = inputString.slice(0, showCharacterIndex);

    // Create a string of asterisks with the same length as the original string
    const maskedChars = "*".repeat(inputString.length - showCharacterIndex);

    // Concatenate the first two characters with the masked characters
    return firstTwoChars + maskedChars;
};

/**
 * Filters an array of guest plays to find plays that occurred on the current date
 * and in the current area of the user.
 *
 * @param {Array} guestPlay - An array of guest play data.
 * @param {Object} userState - The user's state, which includes the current area.
 *
 * @returns {Array} - An array of guest plays that match the criteria.
 */
export const getGuestPlaysToday = (guestPlay, userState) => {
    return filter(guestPlay, (gameplay) => {
        const isSameDate =
            new Date(gameplay.created_at).toLocaleDateString() === new Date().toLocaleDateString();
        const isSameArea = gameplay.area_id === userState?.current_area_id;

        return isSameDate && isSameArea;
    });
};

/**
 * Determine the mobile operating system.
 * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
 *
 * @returns {object} An object containing the mobile OS type - Android/IOS
 */
export const getMobileOperatingSystem = () => {
    const os = {
        isAndroid: false,
        isIOS: false,
    };

    const userAgent = window.navigator.userAgent || window.navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
        return { ...os, isAndroid: true };
    }

    if (/android/i.test(userAgent)) {
        return { ...os, isAndroid: true };
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return { ...os, isIOS: true };
    }

    return { ...os, isAndroid: true };
};

/**
 * Filters an object of empty property values
 *
 * @param {obj} payload - The object to be filtered.
 *
 * @returns {obj} - The filtered object.
 */
export const filterEmptyProps = (payload) => {
    return Object.fromEntries(
        Object.entries(payload)
            ?.map((entry) => {
                if (!entry[1]) {
                    return null;
                }

                return entry;
            })
            ?.filter((entry) => entry)
    );
};

/**
 * Duplicates array elements based on specified count
 *
 * @param {array} data The data to be duplicated
 * @param {count} count The number of data to be duplicated
 *
 * @returns {array} The duplicated data
 */
export const dataDuplicater = ({ data = [], count = 10 }) => {
    if (data?.length >= 10) {
        return data;
    } else if (data?.length === 1) {
        return data;
    } else if (!data?.length) {
        return [];
    }

    let fakedData = [];

    for (let i = 0; i < count; i++) {
        if (fakedData?.length >= 10) break;

        let sliced = data?.slice(0, data?.length + 1);

        if (count - (fakedData?.length + 1) < sliced?.length + 1) {
            sliced = data?.slice(0, count - fakedData?.length);
        }

        sliced?.map((slice) => fakedData.push(slice));
    }

    return fakedData;
};

/**
 * Converts Kilometers to Miles
 *
 * @param {int} km value in km
 *
 * @returns {int} converted km to mi
 *
 */
export const convertKmToMiles = (km) => {
    if (isNaN(km) || !km) {
        return 0;
    }
    return km * 0.621371;
};

/**
 * Shorten number to thousands, millions, billions, etc.
 * http://en.wikipedia.org/wiki/Metric_prefix
 *
 * @param {number} num Number to shorten.
 * @param {number} [digits=0] The number of digits to appear after the decimal point.
 * @returns {string|number}
 *
 */
export const shortenLargeNumber = (num, digits) => {
    let units = ["k", "m", "g", "t", "p", "e", "z", "y"],
        decimal;

    for (let i = units.length - 1; i >= 0; i--) {
        decimal = Math.pow(1000, i + 1);

        if (num <= -decimal || num >= decimal) {
            return +(num / decimal).toFixed(digits) + units[i];
        }
    }

    return num;
};

/**
 * Returns the ordinal suffix for a given number.
 *
 * @param {number} number - The input number for which to determine the ordinal suffix.
 * @returns {string} - The ordinal suffix ("st", "nd", "rd", or "th").
 *
 */
export const nthNumber = (number) => {
    if (number > 3 && number < 21) return `${number}th`;
    switch (number % 10) {
        case 1:
            return `${number}st`;
        case 2:
            return `${number}nd`;
        case 3:
            return `${number}rd`;
        default:
            return `${number}th`;
    }
};

/**
 * Handles parsing of guess, converts it to integer
 * @param {string} guess guess string
 * @returns {number} parsed guess
 */
export const guessParser = (guess) => {
    if (!guess) return 0;
    return parseInt(guess?.toString().substring(1).split(",").join(""));
};

/**
 * Checks if the select location timestamp is from today.
 *
 * @returns {boolean} True if the select location timestamp is from today, false otherwise.
 */
export const hasSelectLocationTimestampToday = () => {
    const selectLocationTimestamp = localStorage.getItem("select_location_timestamp");

    let redirectToDailyProperties = false;

    if (!isEmpty(selectLocationTimestamp) || !isNull(selectLocationTimestamp)) {
        const selectLocationTimestampDiff = moment()
            .tz("America/Chicago")
            .startOf("day")
            .diff(moment(selectLocationTimestamp).tz("America/Chicago").startOf("day"), "days", false);

        redirectToDailyProperties = selectLocationTimestampDiff === 0;
    }

    return redirectToDailyProperties;
};

/**
 * Creates a challenge link and triggers sharing on web and mobile platforms.
 *
 * @async
 * @param {string} score_id - The ID of the score associated with the challenge.
 *
 * @returns {Promise<void>} - A promise that resolves once the challenge link is created and sharing is triggered.
 */
export const createChallengeLink = async (score_id, score) => {
    const challenge = await createChallenge({
        challenger_score_id: score_id,
    });
    const challengeId = challenge?.data?.id;
    await triggerShare({
        web: {
            text: `${config.BASE_URL}/challenge/${challengeId}`,
        },
        mobile: {
            title: "PriceMe",
            text: `I just scored ${score} pts guessing the price of this home on PriceMe 🎈

Think you can beat my score?

Tap^ to guess what it sold for. Expires at midnight.`,
            url: `${config.BASE_URL}/challenge/${challengeId}`,
        },
    });
};

/**
 * Formats a price to USD currency string.
 *
 * @param {number} price - The price to format.
 * @returns {string} The formatted price string.
 */
export const formatPrice = (price) =>
    Number(price).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
        maximumFractionDigits: 0,
    });

/**
 * Checks if the stored timestamp is from the same day as the current day in the "America/Chicago" timezone.
 *
 * @returns {boolean} True if the timestamp is from the same day as today, false otherwise.
 */
export const isSameDayAsTimestamp = () => {
    const timestamp = localStorage.getItem("select_location_timestamp");

    if (!timestamp) {
        return false;
    }

    const timestampDayStart = moment(timestamp).tz("America/Chicago").startOf("day");
    const currentDayStart = moment().tz("America/Chicago").startOf("day");

    return timestampDayStart.isSame(currentDayStart);
};

/**
 * Converts a pixel (px) value to rem units, based on a specified or default base rem value.
 *
 * - If no base rem value is provided, it defaults to the root `<html>` font size.
 * - The function accepts `pxValue` as either a string with "px" suffix (e.g., "16px") or as a number (e.g., 16).
 *
 * @param {number | string} pxValue - The pixel value to convert. Can be a number (e.g., 16) or a string with "px" (e.g., "16px").
 * @param {number} [baseRemValue=parseFloat(getComputedStyle(document.documentElement).fontSize)] - The base rem value for conversion. Defaults to the root font size.
 *
 * @returns {number} - The equivalent value in rem units.
 *
 * @example
 * // Assuming the base font size is 16px:
 * pxToRem(16);       // returns 1
 * pxToRem("32px");   // returns 2
 */
export const pxToRem = (
    pxValue,
    baseRemValue = parseFloat(getComputedStyle(document.documentElement).fontSize)
) => {
    const tempPx = `${pxValue}`.replace("px", "");

    return (1 / baseRemValue) * parseInt(tempPx);
};
