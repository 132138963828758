import axios from "axios";
import { filterEmptyProps } from "../helpers/helpers";

/**
 * Asynchronously fetches default area data
 *
 * @returns {Promise<Object>} A promise that resolves with the default area data.
 */
export const getDefaultArea = async () => {
    return (await axios.get(`/api/areas/default`)).data;
};

/**
 * Asynchronously fetches area data from the server based on the specified area ID.
 *
 * @param {string} areaId - The ID of the area to fetch.
 *
 * @returns {Promise<Object>} A promise that resolves with the area data.
 */
export const getAreaById = async (areaId) => {
    return (await axios.get(`/api/areas/find/${areaId}`)).data;
};

/**
 * Asynchronously fetches all areas
 *
 * @returns {Promise<Object>} A promise that resolves with all the areas.
 */
export const getAllAreas = async () => {
    return (await axios.get(`/api/areas/all`)).data;
};

/**
 * Asynchronously fetches all areas with stats
 *
 * @returns {Promise<Object>} A promise that resolves with all the areas.
 */
export const getAllAreasWithStats = async ({
    userId,
    limit,
    page,
    dateTo,
    dateFrom,
    timeZoneOffset,
    areaId,
    excludeRecents,
}) => {
    const payload = {
        user_id: userId,
        limit,
        page,
        dateTo,
        dateFrom,
        timeZoneOffset: Math.abs(timeZoneOffset),
        areaId,
        excludeRecents,
    };

    // Remove properties with empty values
    const filteredPayload = filterEmptyProps(payload);

    const query = new URLSearchParams(filteredPayload).toString();

    return (await axios.get(`/api/areas/all/stats?${query}`)).data;
};

/**
 * Asynchronously fetches all areas with stats
 *
 * @returns {Promise<Object>} A promise that resolves with all the recent areas.
 */
export const getAllRecentAreas = async ({ userId, limit, page, dateTo, dateFrom, timeZoneOffset }) => {
    const payload = {
        limit,
        page,
        dateTo,
        dateFrom,
        timeZoneOffset: Math.abs(timeZoneOffset),
    };

    // Remove properties with empty values
    const filteredPayload = filterEmptyProps(payload);

    const query = new URLSearchParams(filteredPayload).toString();

    return (await axios.get(`/api/areas/recents/${userId}?${query}`)).data;
};

/**
 * Handles area requests
 *
 * @param {string} areaName - The name of the area.
 * @param {string} zipCode - The zip code of the area.
 *
 */

export const submitAreaRequest = async ({ areaName, zipCode }) => {
    return await axios.post("/api/areas/requests/create", {
        name: areaName,
        zipcode: zipCode,
    });
};

export const searchClosestAreas = async ({ lat, lon, page = 1, limit = 10, user_id }) => {
    const query = new URLSearchParams({ lat, lon, page, limit, user_id }).toString();
    return (await axios.get(`/api/areas/search/closest?${query}`)).data;
};
