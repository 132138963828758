import { useCallback, useEffect, useState, useLayoutEffect } from "react";

const useBrowserLayoutEffect =
    typeof window !== "undefined"
        ? useLayoutEffect
        : // eslint-disable-next-line @typescript-eslint/no-empty-function
          () => {};

/**
 * Get the current size of the Viewport. Do not call this excessively, as it may
 * cause performance issues in WebKit. Querying innerWidth/height triggers a
 * relayout of the page.
 */
export const getViewportSize = () => {
    if (window.visualViewport) {
        // visualViewport is a new prop intended for this exact behavior, prefer it
        // over all else when available
        // https://developer.mozilla.org/en-US/docs/Web/API/Visual_Viewport_API
        return {
            width: window.visualViewport.width,
            height: window.visualViewport.height,
        };
    }

    return {
        width: window.innerWidth,
        // window.innerHeight gets updated when a user opens the soft keyboard, so
        // it should be preferred over documentElement.clientHeight
        // Want more? https://blog.opendigerati.com/the-eccentric-ways-of-ios-safari-with-the-keyboard-b5aa3f34228d
        height: window.innerHeight,
    };
};

/**
 * Returns the viewport size. This can also be used as a dependency in a
 * useEffect to trigger an update when the browser resizes.
 */
const useVisualViewport = () => {
    const [viewportSize, setViewportSize] = useState();
    const updateViewportSize = useCallback(() => {
        const viewportSize = getViewportSize();

        setViewportSize((oldViewportSize) => {
            if (
                oldViewportSize &&
                oldViewportSize.width === viewportSize.width &&
                oldViewportSize.height === viewportSize.height
            ) {
                // Maintain old instance to prevent unnecessary updates
                return oldViewportSize;
            }

            const offsetHeight = oldViewportSize
                ? oldViewportSize.height - viewportSize.height
                : viewportSize.height;

            return { ...viewportSize, offsetHeight };
        });
    }, []);
    useBrowserLayoutEffect(updateViewportSize, [updateViewportSize]);

    useEffect(() => {
        const effectTwice = () => {
            updateViewportSize();
            // Closing the OSK in iOS does not immediately update the visual viewport size :<
            setTimeout(updateViewportSize, 1000);
        };

        window.addEventListener("resize", effectTwice);
        window.addEventListener("scroll", effectTwice);
        // From the top of my head this used to be required for older browsers since
        // this didn't trigger a resize event. Keeping it in to be safe.
        window.addEventListener("orientationchange", effectTwice);
        // This is needed on iOS to resize the viewport when the Virtual/OnScreen
        // Keyboard opens. This does not trigger any other event, or the standard
        // resize event.
        window.visualViewport?.addEventListener("resize", effectTwice);
        window.visualViewport?.addEventListener("scroll", effectTwice);

        return () => {
            window.removeEventListener("resize", effectTwice);
            window.removeEventListener("scroll", effectTwice);
            window.removeEventListener("orientationchange", effectTwice);
            window.visualViewport?.removeEventListener("resize", effectTwice);
            window.visualViewport?.removeEventListener("scroll", effectTwice);
        };
    }, [updateViewportSize]);

    return viewportSize;
};

export default useVisualViewport;
