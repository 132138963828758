import React, { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import ConfettiExplosion from "react-confetti-explosion";
import CorrectGuessIcon from "../../assets/icons-v2/correct-guess.png";
import CustomSvg from "../common/custom-svg/custom-svg";
import PropTypes from "prop-types";

const DEFAULT = {
    durations: {
        checkmarkShow: 0,
        confettiShow: 1000,
        hideAll: 2500,
    },
    checkmarkOptions: {
        animation: {
            initial: { y: "25svh", opacity: 0 },
            animate: { y: "-25svh", opacity: 1 },
            exit: { y: "25svh", opacity: 0 },
            transition: { type: "spring", stiffness: 50, damping: 10 },
        },
        svg: {
            src: CorrectGuessIcon,
            size: { height: 100, width: 100 },
        },
    },
    confettiOptions: {
        particleCount: 50,
        particleSize: 15,
        duration: 3000,
        zIndex: 997,
    },
};

const CheckmarkConfetti = ({ play, setPlay }) => {
    const [showConfetti, setShowConfetti] = useState(false);
    const [checkmarkVisible, setCheckmarkVisible] = useState(false);

    useEffect(() => {
        let timers = [];

        if (play) {
            // Trigger the checkmark and confetti after a short delay
            timers.push(
                setTimeout(() => {
                    setCheckmarkVisible(true);
                }, DEFAULT.durations.checkmarkShow)
            );

            timers.push(
                setTimeout(() => {
                    setShowConfetti(true);
                }, DEFAULT.durations.confettiShow)
            );

            // Reset the checkmark visibility and confetti after the specified duration
            timers.push(
                setTimeout(() => {
                    setCheckmarkVisible(false);
                    setShowConfetti(false);
                    setPlay(false);
                }, DEFAULT.durations.hideAll)
            );
        }

        // Clear timers on unmount or if the `play` prop changes
        return () => {
            timers.forEach((timer) => clearTimeout(timer));
        };

        // Trigger the animation whenever `play` changes
    }, [play]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            <AnimatePresence>
                {checkmarkVisible && (
                    <motion.div
                        className="tw-fixed tw-left-[38%] tw-bottom-0 tw-z-[998]"
                        {...DEFAULT.checkmarkOptions.animation}
                    >
                        <CustomSvg {...DEFAULT.checkmarkOptions.svg} />
                    </motion.div>
                )}
            </AnimatePresence>

            {showConfetti && (
                <ConfettiExplosion
                    className="tw-fixed tw-bottom-[35svh] tw-left-1/2"
                    {...DEFAULT.confettiOptions}
                />
            )}
        </div>
    );
};

CheckmarkConfetti.propTypes = {
    play: PropTypes.bool.isRequired,
    setPlay: PropTypes.func.isRequired,
};

export default CheckmarkConfetti;
