import React from "react";
import PropTypes from "prop-types";
import { motion } from "framer-motion";

const heartVariants = {
    solid: {
        opacity: 1, // Fully visible
        transition: { duration: 1 }, // Quick fade in
    },
    outlined: {
        opacity: 0.3, // Slightly faded before shaking
        transition: { duration: 1 }, // Quick fade out/in when changing to outlined
    },
    shake: {
        x: [0, 2, -2, 2, -2, 0], // Shake left and right
        transition: { duration: 0.6, repeat: 1, repeatType: "mirror" }, // Shake duration and repeat
    },
};

const AnimateShakeAndFade = (props) => {
    return (
        <motion.div
            {...props}
            initial="solid" // Start as a solid heart
            animate={props.play ? ["outlined", "shake"] : "solid"} // If lost, transition through outlined, shake, and fall
            variants={heartVariants}
        >
            {props.children}
        </motion.div>
    );
};

AnimateShakeAndFade.propTypes = {
    show: PropTypes.bool.isRequired,
    children: PropTypes.element.isRequired,
};

export default AnimateShakeAndFade;
