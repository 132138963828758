import React, { memo, useMemo } from "react";
import PropTypes from "prop-types";
import Tile from "./Tile";
import BtnSubmit from "./BtnSubmit";
import { motion, AnimatePresence } from "framer-motion";

const ANIMATION_DELAY_COEFFICIENT = 0.5;

const TileRow = memo(
    ({
        rowIndex,
        length,
        expand,
        gameIndex,
        value,
        checkTileMode,
        inputRef,
        handleSubmit,
        setOngoingAnimation,
        isPropertyGuessed,
    }) => {
        const rowValue = useMemo(() => {
            const guessValue = value ? value.split("") : [];

            return Array.from({ length }, (_, i) => guessValue[i] || "");
        }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

        const showRow = expand || rowIndex === 0 || (!expand && rowIndex <= gameIndex);

        return (
            <AnimatePresence>
                {/* Render the first TileRow when its not yet expanded */}
                {showRow && (
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ ease: "easeOut", duration: 0.3 }}
                        className="tw-flex tw-flex-row tw-flex-wrap tw-items-center tw-justify-center tw-gap-1"
                    >
                        {rowValue.map((columnValue, columnIndex) => (
                            <React.Fragment key={`tile-column-${rowIndex}-${columnIndex}`}>
                                {columnIndex === 2 || columnIndex === 5 ? (
                                    <span className="tw-text-3xl tw-font-medium">,</span>
                                ) : null}
                                <Tile
                                    mode={checkTileMode(rowIndex, columnIndex, columnValue)}
                                    value={columnValue}
                                    onClick={() => inputRef.current.focus()}
                                    animationDelay={columnIndex * ANIMATION_DELAY_COEFFICIENT}
                                    onAnimationComplete={(animationName) => {
                                        if (animationName === "flip") {
                                            if (columnIndex === length - 1) {
                                                setOngoingAnimation("idle");
                                            } else {
                                                setOngoingAnimation("flip");
                                            }
                                        }
                                    }}
                                />
                            </React.Fragment>
                        ))}

                        {!isPropertyGuessed && (
                            <BtnSubmit
                                rowValue={rowValue}
                                handleSubmit={handleSubmit}
                                rowIndex={rowIndex}
                                gameIndex={gameIndex}
                            />
                        )}
                    </motion.div>
                )}
            </AnimatePresence>
        );
    }
);

Tile.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    length: PropTypes.number.isRequired,
    expand: PropTypes.bool.isRequired,
    gameIndex: PropTypes.number.isRequired,
    setOngoingAnimation: PropTypes.func,
    rowIndex: PropTypes.number,
    value: PropTypes.string,
    checkTileMode: PropTypes.func,
    inputRef: PropTypes.object,
    isPropertyGuessed: PropTypes.bool,
};

export default TileRow;
